import React, { useState, useEffect, useContext } from 'react';
import {Table, Button, message, Modal, Row, Col, Form, Input, Select, Switch, Radio, InputNumber, Card, Tabs,} from 'antd';
import { Link } from 'gatsby';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import axios from 'axios';
import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import { Firebase } from '../config/firebase';

const { Option } = Select;
const { TabPane } = Tabs;

const FormBuilder = ({ location }) => {
    const state = useContext(GlobalStateContext);
    const { user, selectedProjectId } = state;
    const [loading, setLoading] = useState(false);
    const [nameForm, setNameForm] = useState('');
    const [forms, setForms] = useState([]);
    const [visible, setVisible] = useState(false);
    // Dynamically added fields
    const [codePreview, setCodePreview] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fieldData, setFieldData] = useState({});
    const [id, setId] = useState('');
    const [formInstance] = Form.useForm();  // Initialize form instance here
    const [formFields, setFormFields] = useState([
        {
            label: '',
            type: 'text',
            required: false,
            columnWidth: '100%',
            placeholder: '',
            options: [],
        },
    ]);

    const fieldTypes = [
        { value: 'text', label: 'Text Input' },
        { value: 'email', label: 'Email' },
        { value: 'password', label: 'Password' },
        { value: 'number', label: 'Number' },
        { value: 'tel', label: 'Telephone' },
        { value: 'url', label: 'URL' },
        { value: 'date', label: 'Date' },
        { value: 'time', label: 'Time' },
        { value: 'datetime-local', label: 'Date and Time' },
        { value: 'month', label: 'Month' },
        { value: 'week', label: 'Week' },
        { value: 'color', label: 'Color Picker' },
        { value: 'range', label: 'Range Slider' },
        { value: 'file', label: 'File Upload' },
        { value: 'hidden', label: 'Hidden Input' },
        { value: 'textarea', label: 'Text Area' },
        { value: 'select', label: 'Dropdown' },
        { value: 'checkbox', label: 'Checkbox' },
        { value: 'radio', label: 'Radio Buttons' },
        { value: 'multiselect', label: 'Multi-Select Dropdown' },
        { value: 'checkboxgroup', label: 'Checkbox Group' },
        { value: 'radiogroup', label: 'Radio Group' },
    ];

    const columns = [
        {
            title: 'Form Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link to={`/form-builder/${record._id}`}>
                    <div>{record.form_name}</div>
                </Link>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'created_date',
            key: 'created_date',
            render: (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a'),
        },
        {
            title: 'Last Updated',
            dataIndex: 'updated_date',
            key: 'updated_date',
            render: (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a'),
        },
        {
            title: 'Fields Count',
            dataIndex: 'fields_count',
            key: 'fields_count',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Link to={`/form-builder/${record._id}`}>
                        <Button type='primary'>Edit</Button>
                    </Link>
                    <Button
                        type='primary'
                        danger
                        onClick={() => handleDelete(record._id)}
                        style={{ marginLeft: 8 }}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    // Fetch the list of available forms
    const fetchForms = async () => {
        if (!user || !user.cloud_name) return;

        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.GATSBY_BASE_URL}/form-definitions`,
                {
                    params: { projectId: selectedProjectId, cloud_name: user.cloud_name },
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (response.data && response.data.length > 0) {
                setForms(response.data);
            } else {
                setForms([]);
            }
        } catch (error) {
            console.error('Error fetching forms:', error);
            message.error('Failed to fetch forms');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user && user.cloud_name) {
            fetchForms();
        }
    }, [user, selectedProjectId]);

    const handleDelete = (formId) => {
        if (
            window.confirm(
                `Are you sure you want to delete Form?`,
            )
        ) {
            Firebase.auth().onAuthStateChanged((user) => {
                axios.delete(`${process.env.GATSBY_BASE_URL}/form-definitions/${formId}`,
                    { headers: { 'Content-Type': 'application/json' } },
                )
                    .then((res) => {
                        message.info(
                            `Form deleted successfully!`,
                        );
                        setForms((prevForms) => prevForms.filter(form => form._id !== formId));

                    })
                    .catch((error) => {
                        message.info(
                            'Something is not right!',
                        );
                    });
            });
        }
    };

    // Handle the modal visibility
    const handleModalOk = async () => {
        try {
            const values = await formInstance.validateFields();

            // Check if there are empty fields (excluding optional ones)
            if (!values.formName || !values.field || !values.type || !values.label) {
                console.error('Please fill in all required fields');
                return;
            }

            let processedValues = { ...values };

            // Process options for select, multiselect, checkbox group, and radio group
            if (['select', 'multiselect', 'checkboxgroup', 'radiogroup'].includes(values.type)) {
                processedValues.options = values.options ? values.options.split('\n').filter(option => option.trim() !== '') : [];
            }

            // Add a style object
            processedValues.style = {
                borderStyle: values.borderStyle,
                borderWidth: values.borderWidth,
                borderColor: values.borderColor,
                textColor: values.textColor,
                backgroundColor: values.backgroundColor,
            };

            // Remove individual style properties
            delete processedValues.borderStyle;
            delete processedValues.borderWidth;
            delete processedValues.borderColor;
            delete processedValues.textColor;
            delete processedValues.backgroundColor;

            const createFields = formFields.map(field => ({ ...field, ...processedValues }));

            setFormFields(createFields);
            setIsModalVisible(false);

            // Generate code
            generateCodePreview(createFields);

            if (id) {
                updateFormFields(createFields, id)
            }
            else {
                // Save to API
                saveFormFields(createFields);
            }
        } catch (error) {
            console.error('Form validation error:', error);
        }
    };
    const generateCodePreview = (fields) => {
        if (!fields || fields.length === 0) {
            setCodePreview('// No fields to display');
            return;
        }

        let formHtml = `
    <!DOCTYPE html>
    <html lang='en'>
    <head>
    <meta charset='UTF-8'>
    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
    <title>Form</title>
    <style>
        .form-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            font-family: Arial, sans-serif;
        }
        .form-row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
        }
        .form-field {
            margin-bottom: 15px;
            box-sizing: border-box;
        }
        .form-field label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
        }
        .form-field input, .form-field select, .form-field textarea {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
        }
        .required {
            color: red;
            margin-left: 3px;
        }
        .submit-button {
            background-color: #4CAF50;
            color: white;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
        .submit-button:hover {
            background-color: #45a049;
        }
    </style>
</head>
<body>
    <div class='form-container'>
        <form id='myForm'>
`;

        // Group fields by row based on column width
        let currentRow = [];
        let currentRowWidth = 0;
        let rows = [];

        fields.forEach(field => {
            const width = field.columnWidth === '50%' ? 0.5 : 1;

            if (currentRowWidth + width > 1) {
                rows.push([...currentRow]);
                currentRow = [field];
                currentRowWidth = width;
            } else {
                currentRow.push(field);
                currentRowWidth += width;
            }
        });

        if (currentRow.length > 0) {
            rows.push(currentRow);
        }

        // Generate HTML for each row
        rows.forEach(row => {
            formHtml += `            <div class='form-row'>\n`;

            row.forEach(field => {
                const width = field.columnWidth || '100%';
                const required = field.required ? '<span class="required">*</span>' : '';
                const fieldStyle = field.style ?
                    `style="border: ${field.style.borderWidth}px ${field.style.borderStyle} ${field.style.borderColor}; color: ${field.style.textColor}; background-color: ${field.style.backgroundColor};"` : '';

                formHtml += `<div class='form-field' style='width: ${width}; padding-right: 10px;'>\n`;
                formHtml += `<label for='${field.field}'>${field.label || field.field}${required}</label>\n`;

                switch (field.type) {
                    case 'textarea':
                        formHtml += `<textarea id='${field.field}' name='${field.field}' placeholder='${field.placeholder || ''}' ${field.required ? 'required' : ''} ${fieldStyle}></textarea>\n`;
                        break;
                    case 'select':
                    case 'multiselect':
                        formHtml += `<select id='${field.field}' name='${field.field}' ${field.multiple ? 'multiple' : ''} ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                        if (field.options && field.options.length > 0) {
                            field.options.forEach(option => {
                                formHtml += `<option value='${option}'>${option}</option>\n`;
                            });
                        }
                        formHtml += `</select>\n`;
                        break;
                    case 'checkbox':
                        formHtml += `<input type='checkbox' id='${field.field}' name='${field.field}' ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                        break;
                    case 'checkboxgroup':
                        if (field.options && field.options.length > 0) {
                            field.options.forEach((option, index) => {
                                formHtml += `<div>\n`;
                                formHtml += `<input type='checkbox' id='${field.field}_${index}' name='${field.field}' value='${option}' ${fieldStyle}>\n`;
                                formHtml += `<label for='${field.field}_${index}'>${option}</label>\n`;
                                formHtml += `</div>\n`;
                            });
                        }
                        break;
                    case 'radiogroup':
                        if (field.options && field.options.length > 0) {
                            field.options.forEach((option, index) => {
                                formHtml += `<div>\n`;
                                formHtml += `<input type='radio' id='${field.field}_${index}' name='${field.field}' value='${option}' ${fieldStyle} ${field.required ? 'required' : ''}>\n`;
                                formHtml += `<label for='${field.field}_${index}'>${option}</label>\n`;
                                formHtml += `</div>\n`;
                            });
                        }
                        break;
                    default:
                        formHtml += `<input type='${field.type}' id='${field.field}' name='${field.field}' placeholder='${field.placeholder || ''}' ${field.required ? 'required' : ''} ${fieldStyle}>\n`;
                }

                formHtml += `</div>\n`;
            });

            formHtml += `</div>\n`;
        });

        formHtml += `
            <div class='form-row'>
                <div class='form-field' style='width: 100%;'>
                    <button type='submit' class='submit-button'>Submit</button>
                </div>
            </div>
        </form>
    </div>
    <script>
        document.getElementById('myForm').addEventListener('submit', function(event) {
            event.preventDefault();
            
            const formData = new FormData(this);
            const formFields = [];
            
            for (const [field, value] of formData.entries()) {
                formFields.push({
                    field: field,
                    value: value
                });
            }
            
            // Replace with your NoCodeAPI endpoint
            fetch('https://v1.nocodeapi.com/your-username/nForms/your-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fields: formFields })
            })
            .then(response => response.json())
            .then(data => {
                alert('Form submitted successfully!');
                document.getElementById('myForm').reset();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('There was an error submitting the form.');
            });
        });
    </script>
</body>
</html>
`;
        setCodePreview(formHtml);
    };

    const saveFormFields = async (fields) => {
        if (!user || !user.cloud_name) return;

        setLoading(true);
        try {
            const response =  await axios.post(
                `${process.env.GATSBY_BASE_URL}/form-definitions`,
                {
                    name: nameForm,
                    fields: fields,
                    projectId: selectedProjectId,
                    cloud_name: user.cloud_name,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            );
            if (response.data.data._id) {
                setId(response.data.data._id)
            }
            message.success('Form fields created successfully');
        } catch (error) {
            console.error('Error saving form fields:', error);
            message.error('Failed to save form fields');
        } finally {
            setLoading(false);
        }
    };
    const updateFormFields = async (fields, id) => {
        if (!user || !user.cloud_name) return;

        setLoading(true);
        try {
            await axios.put(
                `${process.env.GATSBY_BASE_URL}/form-definitions/${id}`,
                {
                    name: nameForm,
                    fields: fields,
                    projectId: selectedProjectId,
                    cloud_name: user.cloud_name,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            );
            message.success('Form fields updated successfully');
        } catch (error) {
            console.error("Error saving form fields:", error);
            message.error("Failed to save form fields");
        } finally {
            setLoading(false);
        }
    };
    const closeFieldModal = () => {
        setFormFields([{
            label: '',
            type: 'text',
            required: false,
            columnWidth: '100%',
            placeholder: '',
            options: [],
        }]);
        setCodePreview('');
        setVisible(false);
        formInstance.resetFields();
        setId(() => '');
        fetchForms();
    };
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleFieldClick = (field) => {
        setFieldData(field);
        setIsModalVisible(true);
    };

    const renderFieldOptions = () => {
        if (fieldData.type === 'select') {
            return (
                <Form.Item
                    name='options'
                    label='Options'
                    rules={[{ required: true, message: 'Please enter field options' }]}
                >
                    <Input.TextArea rows={4} placeholder='Comma separated options (e.g., Option 1, Option 2)' />
                </Form.Item>
            );
        }
        return null;
    };

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(codePreview);
        message.success('Code copied to clipboard');
    };


    // Handle adding new field to form
    // const addField = () => {
    //     const newField = {
    //         label: '',
    //         type: 'text', // default field type
    //         required: false,
    //         columnWidth: '100%',
    //         placeholder: '',
    //         options: [],
    //     };
    //     setFormFields([...formFields, newField]);
    // };

    // Table columns

    return (
        <>
            <SEO title='Form Builder — NoCodeAPI' />
            <AppContainer activeNav='form-builder' location={location}>
                <div className='two-col-page-head-section'>
                    <div className='page-content-header'>
                        <h2>Form Builder</h2>
                        <p>Manage and customize your forms</p>
                    </div>
                    <Button
                        type='primary'
                        className='make-btn'
                        style={{
                            fontSize: 13,
                            fontWeight: 400,
                        }}
                        onClick={() => setVisible(true)}
                    >
                        Create Form
                    </Button>
                </div>

                <Modal
                    centered
                    className='playground-modal'
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => closeFieldModal()}
                    width='95%'
                    bodyStyle={{ minHeight: '85vh' }}
                    footer={null}
                    keyboard={false}
                >
                    <Row gutter={24}>
                        {/* Left Column - Form Fields */}
                        <Col span={12}>
                            <h3>Form Fields</h3>
                            <p>Click on a field to create its properties</p>
                            <Row gutter={[16, 16]}>
                                {formFields.map((field, index) => (
                                    <Col key={index} xs={24} sm={field.columnWidth === '50%' ? 12 : 24}>
                                        <Card
                                            title={
                                                <span>
                                                        {field.label || 'Field'}
                                                    {field.required &&
                                                        <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
                                                    </span>
                                            }
                                            hoverable
                                            onClick={() => handleFieldClick(field)}
                                            style={{
                                                borderColor: field.style?.borderColor || '#d9d9d9',
                                                borderWidth: field.style?.borderWidth || 1,
                                                borderStyle: field.style?.borderStyle || 'solid',
                                            }}
                                        >
                                            <p><strong>Type:</strong> {field.type || 'text'}</p>
                                            <p><strong>Width:</strong> {field.columnWidth || '100%'}</p>
                                            {field.placeholder && (
                                                <p><strong>Placeholder:</strong> {field.placeholder}</p>
                                            )}
                                            {field.options && (
                                                <p><strong>Options:</strong> {field.options.join(', ')}</p>
                                            )}
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                        {/* Right Column - Code Preview */}
                        <Col span={12}>
                            <h3>Code Preview</h3>
                            <p>Copy this code to include the form on your website</p>

                            <div style={{ position: 'relative' }}>
                                <Button
                                    type='primary'
                                    icon={<CopyOutlined />}
                                    onClick={copyCodeToClipboard}
                                    style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
                                >
                                    Copy Code
                                </Button>
                                <div style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: '25px',
                                    borderRadius: '4px',
                                    maxHeight: '600px',
                                    overflowY: 'auto',
                                    fontFamily: 'monospace',
                                    whiteSpace: 'pre-wrap',
                                    fontSize: '12px',
                                }}>
                                    {codePreview}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Modal for Editing Field */}
                    <Modal
                        title='New Field'
                        visible={isModalVisible}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                        confirmLoading={loading}
                        width={800}
                    >
                        <Form
                            form={formInstance}  // Pass formInstance here
                            layout='vertical'
                        >

                            <Tabs defaultActiveKey='1'>
                                <TabPane tab='Basic Settings' key='1'>
                                    <Form.Item
                                        name='formName'
                                        label='Form Name'
                                        rules={[{ required: true, message: 'Please enter a form name' }]}
                                    >
                                        <Input
                                            placeholder='Enter Form Name'
                                            value={nameForm}
                                            onChange={(e) => setNameForm(e.target.value)}
                                        />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name='field'
                                                label='Field Name'
                                                rules={[{ required: true, message: 'Please enter the field name' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name='type'
                                                label='Field Type'
                                                rules={[{ required: true, message: 'Please select the field type' }]}
                                            >
                                                <Select>
                                                    {fieldTypes.map(type => (
                                                        <Option key={type.value}
                                                                value={type.value}>{type.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name='label'
                                                label='Label'
                                                rules={[{ required: true, message: 'Please enter the field label' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name='placeholder'
                                                label='Placeholder'
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name='required'
                                                label='Required'
                                                valuePropName='checked'
                                            >
                                                <Switch />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name='columnWidth'
                                                label='Column Width'
                                            >
                                                <Radio.Group>
                                                    <Radio.Button value='50%'>50%</Radio.Button>
                                                    <Radio.Button value='100%'>100%</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {renderFieldOptions()}
                                </TabPane>
                                <TabPane tab='Styling' key='2'>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name='borderStyle'
                                                label='Border Style'
                                            >
                                                <Select>
                                                    <Option value='none'>None</Option>
                                                    <Option value='solid'>Solid</Option>
                                                    <Option value='dashed'>Dashed</Option>
                                                    <Option value='dotted'>Dotted</Option>
                                                    <Option value='double'>Double</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name='borderWidth'
                                                label='Border Width (px)'
                                            >
                                                <InputNumber min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name='borderColor'
                                                label='Border Color'
                                            >
                                                <Input type='color' />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name='textColor'
                                                label='Text Color'
                                            >
                                                <Input type='color' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name='backgroundColor'
                                                label='Background Color'
                                            >
                                                <Input type='color' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Modal>
                </Modal>

                <div className='project-edit'>
                    <Table
                        dataSource={forms}
                        columns={columns}
                        rowKey='id'
                        loading={loading}
                        pagination={false}
                    />
                </div>
            </AppContainer>
        </>
    );
};

export default FormBuilder;
